import axios from "axios";
import { useState, useEffect } from "react";

const AddReels = () => {
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [name, setName] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [videoid, setVideoUrl] = useState("");
  const [selectedSubject, setSelectedSubject] = useState('');

  const fetchSubjects = async (gradeId) => {
    try {
      const res = await axios.get(`https://backend.zuruorg.site/api/v1/auth/getsubjects/${gradeId}`);
      if (res.status === 200) {
        setSubjects(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const res = await axios.get("https://backend.zuruorg.site/api/v1/auth/getgrades");
        if (res.status === 200) {
          setGrades(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchGrades();
  }, []);

  useEffect(() => {
    if (selectedGrade) {
      fetchSubjects(selectedGrade);
    }
  }, [selectedGrade]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      selectedGrade,
      selectedSubject,
      videoid,
    };

    console.log("Payload:", payload);

    try {
      const res = await axios.post("https://backend.zuruorg.site/api/v1/admin/add-reel", payload);

      console.log("Response:", res);

      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="title">
          <h2>Control Panel</h2>
          <p>For Zuru team only</p>
        </div>

        <div className="passport">
          <div className="custom-file">
            <label>Grade: </label>
            <br/>
            <select
              className="form-group col-md-6"
              name="grade"
              id="grade-select"
              required
              size={1}
              value={selectedGrade}
              onChange={(e) => setSelectedGrade(e.target.value)}
            >
              <option value="">Please select grade</option>
              {grades.map(grade => (
                <option value={grade._id} key={grade._id}>{grade.grade}</option>
              ))}
            </select>
            <br/>
            <label>Subject: </label>
            <select
              className="form-group col-md-6"
              name="subject"
              id="subject-select"
              required
              size={1}
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              <option value="">Please select Subject</option>
              {subjects.map(subject => (
                <option value={subject._id} key={subject._id}>{subject.name}</option>
              ))}
            </select>
            <h3>Add Topic Video</h3>
            <h6>Please upload only one video</h6>
            <h4>MP4</h4>
            <a href="#" className="don_icon"><i className="ion-android-done"></i></a>
            
            <label>Video URL: </label>
            <input
              type="text"
              name="videoUrl"
              value={videoid}
              onChange={(e) => setVideoUrl(e.target.value)}
              required
            />
          </div>
        </div>
        <br/>
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default AddReels;
